import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import axios from 'axios'

Vue.use(Router);

const API_URL = process.env.API_URL || 'https://arapi.gora.studio';

let Api = {
  v2: axios.create({
    baseURL: API_URL+'/v2'
  })
};

export function createRouter (store) {

  let router = new Router({
    routes: routes(store),
    mode: 'history',
    fallback: false,
    scrollBehavior: (to, from, savedPosition) => {

      if(process.browser) {
        if (router.app['history'].indexOf(to.name) > -1)
          router.app['history'] = router.app['history'].slice(0, router.app['history'].indexOf(to.name));

        router.app['history'].push(to.name);
      }

      if (savedPosition) return savedPosition;
      else if (to.hash) return { selector: to.hash };
      else return { x: 0, y: 0 }
    }
  });

  if(!process.browser) {
    router.beforeEach((to, from, next) => {

      if(!to.matched.some(record => record.meta.guest))
      {
        if(!store.state.token) next({ name: 'Login'});
        else next();
      }
      else next();
    });
  }

  router.beforeEach((to, from, next) => {

    if(to.matched.some(record => record.meta.isOwner))
    {
      const { token } = (process.browser) ? window.__INITIAL_STATE__ : store.state;
      Api.v2.defaults.headers.common['x-access-token'] = token;
      Api.v2.get('arp/my')
      .then(res => res.data)
      .then(data => {
        const exist = Boolean(data.arps.filter(arp => (arp.idt_arp == to.params.arr_id && !arp.blocked)).length);
        if (exist) {
          next();
        } else {
          next('/404'); 
        }
      })
      .catch(err => { console.log("Error", err); next(); });
    } else {
      next();
    }
      
  });

  return router
}
