const _import = file => () => import(`../templates/pages/${file}`);
const _layout = file => () => import(`../templates/layouts/${file}`)

export default function () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: _import('Login'),
      meta: {
        guest: true
      }
    },
    {
      path: '/recovery',
      name: 'Recovery',
      component: _import('Recovery'),
      meta: {
        guest: true
      }
    },
    {
      path: '/',
      component: _layout('admin'),
      children: [
        {
          path: '/',
          name: 'Arr_List',
          component: _import('Arr_List')
        },

        {
          path: '/profile',
          name: 'Profile',
          component: _import('Profile')
        },

        {
          path: '/arr/:arr_id/round/:round_id',
          name: 'Round',
          props: true,
          component: _import('Round'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/rounds',
          name: 'Rounds',
          props: true,
          component: _import('Rounds'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/mask/:model_id/:modification_id?',
          name: 'Mask',
          props: true,
          component: _import('Model-Combi'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/3d/:model_id/:modification_id?',
          name: 'Model',
          props: true,
          component: _import('Model-Combi'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/gif/:model_id/:modification_id?',
          name: 'Gif',
          props: true,
          component: _import('Model-Combi'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/image/:model_id/:modification_id?',
          name: 'Image',
          props: true,
          component: _import('Model-Combi'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/video/:model_id/:modification_id?',
          name: 'Video',
          props: true,
          component: _import('Model-Combi'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id',
          name: 'Arr',
          props: true,
          component: _import('Arr'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/rounds/map',
          name: 'Rounds_Map',
          props: true,
          component: _import('Rounds_Map'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/models/:round_type/:is_active',
          name: 'Models',
          props: true,
          component: _import('Models'),
          meta: { isOwner: true }
        },

        {
          path: '/arr/:arr_id/round/post',
          name: 'Round_Post',
          props: true,
          component: _import('Round_Post'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/round/post/3d',
          name: 'Round_Post_3D',
          props: true,
          component: _import('Round_Post_3D'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/round/post/gif',
          name: 'Round_Post_Gif',
          props: true,
          component: _import('Round_Post_Gif'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/round/post/photo',
          name: 'Round_Post_Photo',
          props: true,
          component: _import('Round_Post_Photo'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/round/post/youtube',
          name: 'Round_Post_Youtube',
          props: true,
          component: _import('Round_Post_Youtube'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/ref_image/new/:modelId/:modId',
          name: 'Ref_Image_New',
          props: true,
          component: _import('Ref_Image_New'),
          meta: { isOwner: true }
        },
        {
          path: '/arr/:arr_id/ref_image/:refImageId',
          name: 'Ref_Image_Edit',
          props: true,
          component: _import('Ref_Image_Edit'),
          meta: { isOwner: true }
        },
        {
          path: '/user/:id',
          name: 'User',
          props: true,
          component: _import('User')
        },
        {
          path: "/blocked/:arr_id",
          name: 'Blocked',
          props: true,
          component: _import('Arp_Blocked')
        },

        {
          path: "*",
          name: 'PageNotFound',
          component: _import('Error_404')
        }
        
      ]
    }
  ]
}
