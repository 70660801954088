import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

const API_URL = process.env.API_URL || 'https://arapi.gora.studio';

let Api = {
  v1: axios.create({
    baseURL: API_URL+'/v1'
  }),

  v2: axios.create({
    baseURL: API_URL+'/v2'
  })
};

Vue.use(Vuex);

export function createStore () {
  return new Vuex.Store({

    state: () => ({
      isProd: process.env.NODE_ENV === 'production',
      user: null,
      token: null,
      lang: null,
      arp: null,
      arps: null,
    }),

    actions: {
      fetch ({ commit, getters }) {

        Vue.axios.defaults.headers.common['x-access-token'] = getters.getToken();
        axios.defaults.headers.common['x-access-token'] = getters.getToken();
        Api.v1.defaults.headers.common['x-access-token'] = getters.getToken();
	    	Api.v2.defaults.headers.common['x-access-token'] = getters.getToken();

		    return Api.v1.get('/user/profile')
          .then(response => {
            commit('setUser', response.data.profile);

            return response.data;
          })
          .catch((err) => {

            if (err.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            }
            else if (err.request) console.log(err.request);
            else console.log('Error', err.message);
            console.log(err.config);
          })
      },
      login ({ commit }, data) {
        return axios.post('/login-session', data)
          .then((res) => {
            commit('setToken', res.data.token);
            localStorage.setItem('token', res.token);
            return res.data;
          })
      },
      logout ({ commit, getters }, data) {
        localStorage.removeItem('token');
        return axios.delete('/login-session', { data: { idt_session: data.idt_session } })
      },
      clear ({ commit }) {
        commit('setUser', null);
        commit('setToken', null);
        commit('setPage', '');
      },
      language({ commit }, data) {
        commit('setLanguage', data);
        localStorage.setItem('language', data);
      },
      arp({ commit }, data) {
        commit('setArp', data);
        //localStorage.setItem('language', data);
      },
      arps({ commit }, data) {
        commit('setArps', data);
      },
    },

    mutations: {
      setUser (state, data) {
        Vue.set(state, 'user', data)
      },
      setToken (state, data) {
        Vue.set(state, 'token', data)
      },
      setLanguage(state, data) {
        Vue.set(state, 'lang', data)
      },
      setArp(state, data) {
        Vue.set(state, 'arp', data)
      },
      setArps(state, data) {
        Vue.set(state, 'arps', data)
      }
    },

    getters: {
      getToken: state => () => {
        return state.token;
      }
    }
  })
}
