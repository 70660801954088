var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "progress",
    style: {
      width: this.percent + "%",
      height: this.height,
      backgroundColor: this.canSuccess ? this.color : this.failedColor,
      opacity: this.show ? 1 : 0
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }