import axios from 'axios'

const API_URL = process.env.API_URL || 'https://arapi.gora.studio';
const API_REC_URL = process.env.API_REC_URL || 'https://ar-recognize.gora.studio';

let Api = {
  v1: axios.create({
    baseURL: API_URL+'/v1'
  }),

  v2: axios.create({
    baseURL: API_URL+'/v2'
  }),

  rec: {
    v1: axios.create({
      baseURL: API_REC_URL+'/v1'
    })
  },

  axios,

  setToken(token) {
    this.v1.defaults.headers.common['x-access-token'] = token;
    this.v2.defaults.headers.common['x-access-token'] = token;
  }
};

export default {
  install: function(Vue) {
    Vue.prototype.$api = Api;
  },
  $api: Api
}
