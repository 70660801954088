import Vue from 'vue'

import Api from '../scripts/api'
Vue.use(Api);

import Check from '../scripts/check'
Vue.use(Check);

import U2D from '../scripts/u2d'
Vue.use(U2D);

import Render from '../scripts/render'
Vue.use(Render);

import Navbar from '../scripts/navbar'
Vue.use(Navbar);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);
