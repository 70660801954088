import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
	en: {
		date: {
			months: {
				short: {
					jan: 'Jan',
					feb: 'Feb',
					mar: 'Mar',
					apr: 'Apr',
					may: 'May',
					jun: 'Jun',
					jul: 'Jul',
					aug: 'Aug',
					sep: 'Sep',
					oct: 'Oct',
					nov: 'Nov',
					dec: 'Dec',
				},
				long: {
					january: 'January',
					february: 'February',
					march: 'March',
					april: 'April',
					may: 'May',
					june: 'June',
					july: 'July',
					august: 'August',
					september: 'September',
					october: 'October',
					november: 'November',
					december: 'December',
				}
			}
		},
		manual: "Manual",
		embed_manual: "Embed manual",
		embed_customization: "If you want to use a button design other than ARROUND, call the",
		embed_customization_2: "Then you must call this method for open AR",
		embed_preview: "Preview",
		ar_widget: "AR widget for embedding on a web page",
		arr_public_page: "Business public page",
		copy: "Copy",
		// Login screen
		password: 'Password',
		forgot_pwd: 'Forgot password?',
		login: 'Login',
		// Side menu
		dashboard: 'Dashboard',
		users: 'Users',
		business: 'Business',
		rounds: 'Rounds',
		models: 'Models',
		gifs: 'GIFs',
		videos: 'Videos',
		images: 'Images',
		masks: 'Masks',
		options: 'Options',
		logout: 'Logout',
		profile: 'Profile',
		// Dashboard page
		welcome: 'Welcome',
		new_round: 'New Round',
		new_model: 'New Model',
		new_group: 'New Group',
		last_models: 'Last Models',
		last_rounds: 'Last Rounds',
		view_all: 'View All',
		// Users page
		search_placeholder: 'Search...',
		// Businesses page
		new_business: 'New Business',
		businesses: 'Businesses',
		// Rounds page
		open_map: 'Open Map',
		open_list: 'Open List',
		round_del_title: 'Delete round(s)',
		round_del_confirm: 'Confirm that you are sure you want to delete round(s)',
		// Models page
		all_models: 'All models',
		wo_group: 'Without group',
		groups: 'Groups',
		all: 'All',
		active: 'Active',
		disabled: 'Disabled',
		edit_group: 'Edit Group',
		// Model card
		card_refs_status: 'No references',
		// User page
		sex: 'Sex',
		user: 'User',
		sex_type_1: 'Not set',
		sex_type_2: 'Female',
		sex_type_3: 'Male',
		activity: 'Activity',
		sessions: 'Sessions',
		user_sessions: 'User Sessions',
		last_rounds: 'Last Rounds',
		last_models: 'Last Models',
		// Business page
		set_owner: 'Set Owner',
		business_owner_desc: 'Enter email to set ARR Owner. New Owner will receive email with instructions',
		business_owner_new: 'Set new Owner',
		// Round page
		round: 'Round',
		comments: 'Comments',
		views: 'Views',
		likes: 'Likes',
		dislikes: 'Dislikes',
		round_msg: 'Round Message',
		round_pos: 'Round Position',
		round_model: 'Round Model',
		open_web: 'Open in Web',
		del: 'DELETE',
		del_confirm: 'Confirm that you are sure you want to delete the round.',
		del_round: 'Delete round',
		close: 'Close',
		round_deleted: 'Round deleted',
		// Round Post
		round_plane_height: 'Scene height',
		round_create_title: 'Select round type',
		// Round Post page
		round_photo: 'Photo Round',
		round_3d: '3D Round',
		round_yt: 'Youtube Round',
		round_gif: 'Gif Round',
		// Round Post Photo page
		make_round_photo: 'Make Photo Round',
		visible: 'Visible in Recomendation feed',
		round_options: 'Round Options',
		sel_user: 'Select User',
		scale: 'Scale',
		height: 'Height (cm)',
		desc: 'Description',
		lat: 'Lat',
		lng: 'Lng',
		place_name: 'Place Name',
		place_address: 'Place Address',
		hide_loc: 'Hide Location',
		hide_loc_desc: 'You can hide location from Public, but is still be in Round details',
		round_post: 'Post Round',
		// Round Post 3D page
		make_round_3d: 'Make 3D Round',
		load_more: 'Load More',
		// Round Post Youtube
		make_round_yt: 'Create Youtube Round',
		round_yt_title: 'Youtube Video',
		round_yt_url: 'Video URL or ID',
		// New model popup
		new_model_desc: 'Enter model details',
		new_model_name: 'Name',
		round_type: 'Round Type',
		type_holder: 'Type is not selected',
		group: 'Group',
		group_holder: 'Group is not selected',
		save: 'Save',
		// New group popup
		new_group_desc: 'Group is menu item in application for organizing models',
		new_group_name: 'Group Name',
		group_type: 'Group Type',
		group_type_holder: 'Type is not selected',
		// New business popup
		new_business_desc: 'Business are where customers can load models and post rounds from their admin panel. They’re best when you need to add new ARR in around world',
		new_business_name: 'Business Name',
		new_business_add: 'Add Business',
		// Model page
		model_modification: 'Modification',
		model_warn: 'Warning',
		model_title: 'Model',
		model_name: 'Model Name',
		model_group: 'Model Group',
		model_group_holder: 'Select group',
		model_show: 'Show Model',
		model_modifications: 'Modifications',
		model_mod_show: 'Make active',
		model_mod_name: 'Modification Name',
		model_vis: 'Model Visibility',
		model_vis_ios: 'Visible on iOS',
		model_vis_android: 'Visible on Android',
		model_vis_web: 'Visible on Web',
		model_ios_warn: 'The model has no iOS Archive',
		model_android_warn: 'The model has no Android Archive',
		model_ios_mask_warn: 'The mask has no iOS Archive',
		model_ios_tex_warn: 'The mask has no iOS Texture',
		model_android_sfb_mask_warn: 'The mask has no Android SFB',
		model_android_texture_mask_warn: 'The mask has no Android Texture',
		model_web_mask_warn: 'The mask has no Web Archive',
		model_archives: 'Model Archives',
		model_arch_prev: 'Preview',
		model_arch_dl: 'Download',
		model_arch_upl: 'Upload',
		model_audio: 'Model Audio',
		model_audio_file: 'Audio File',
		audio_play: 'Play',
		audio_mode: 'Audio Mode',
		audio_mode_once: 'Once',
		audio_mode_loop: 'Loop',
		audio_mode_tap: 'On tap',
		audio_mode_dis: 'Disabled',
		model_rec_imgs: 'Model Recognize Images',
		model_rec_old: 'Old Recognize',
		model_rec_server: 'Recognize Images On Server',
		model_save: 'Save',
		model_updated: 'Updated',
		// Gif page
		gif_title: 'Gif',
		gif_name: 'Gif Name',
		gif_group: 'Gif Group',
		gif_show: 'Show Gif',
		gif_vis: 'Gif Visibility',
		gif_src: 'Gif Source',
		gif_imgs: 'Gif Images',
		gif_warn: 'The Gif has no uploaded source',
		// Video page
		video_title: 'Video',
		video_name: 'Video Name',
		video_group: 'Video Group',
		video_show: 'Show Video',
		video_vis: 'Video Visibility',
		video_src: 'Video Source',
		video_new: 'Replace',
		video_imgs: 'Video Images',
		video_warn: 'The Video has no uploaded source',
		// Image page
		img_title: 'Image',
		img_name: 'Image Name',
		img_group: 'Image Group',
		img_show: 'Show Image',
		img_vis: 'Image Visibility',
		img_imgs: 'Image References',
		// Mask page
		mask_title: 'Mask',
		// Ref image create
		ref_title: 'New Ref Image',
		ref_sub_title: 'Enter image details',
		ref_pic_width: 'Width of picture in real world (cm)',
		ref_global_loc: 'Global Location',
		ref_rec_server: 'Server Recognize',
		ref_rec_percent: 'Recognize accuracy (%)',
		ref_img_exist: 'Exist',
		ref_sticky: 'Sticky',
		ref_new_msg: 'New ref image was added',
		ref_edit_msg: 'Ref image was modified',
		// Ref image edit
		ref_edit_btn: 'Edit',
		ref_edit_title: 'Edit Ref Image',
		ref_del_confirm: 'Confirm that you are sure you want to delete the image',
		ref_del: 'Delete image',
		ref_exist_btn: 'Exist',
		ref_ocr: 'Words from image',
		ref_key_words: 'Key words (separated by comma)',
		ref_info_name: 'Name',
		ref_info_date: 'Creation Date',
		ref_info_on: 'On',
		ref_info_off: 'Off',
		ref_info_key_words: 'Key words',
		// Options page
		//opt_lifetime_days: 'day(s)',
		opt_lifetime_title: 'Round limit on map (in days)',
		// ARR Block
		arr_manager: 'ARR Manager',
		arr_mine: "My ARR's",
		arr_invite_adm: 'Invite admin',
		arr_invite_desc: 'Enter email to invite him in ARR. New Admin will receive email with instructions',
		arr_invite_btn: 'Invite',
		arr_name: 'Name',
		arr_site: 'Site',
		arr_phone: 'Phone',
		arr_about: 'About',
		arr_links: 'Links',
		arr_admins: 'Administrations',
		arr_embed: 'Embed ARROUND',
		arr_profile_fname: 'First Name',
		arr_profile_lname: 'Last Name',
		arr_profile_uname: 'Username',
		arr_profile_uname_exist: 'Username exists',
		arr_admin_del_title: 'Delete admin',
		arr_admin_del_desc: 'Are you sure you want to delete',
		arr_blocked_account: 'Account',
		arr_blocked_desc: 'We are sorry, but this account has been deactivated',
		arr_absence: 'You have no business accounts',
		// Errors
		err_ref_file: 'File format not supported',
		err_ref_small: 'Ref image is too small',
		err_ref_quality: 'Ref image quality is too low',
		err_ref_match: 'Ref image matches other image',
		err_server: 'Server error',
		err_ref_size: 'Image size less than recommended',
		err_ref_chars: 'Wrong chars in key words',
		// Success
		succ_andr_arch_upd: 'Android archive updated',
		succ_ios_arch_upd: 'iOS archive updated',
		succ_web_arch_upd: 'Web archive updated',
		succ_mask_arch_upd: 'Mask updated',
		succ_audio_upd: 'Audio updated',
	},
	ru: {
		date: {
			months: {
				short: {
					jan: 'янв.',
					feb: 'февр.',
					mar: 'март',
					apr: 'апр.',
					may: 'май',
					jun: 'июнь',
					jul: 'июль',
					aug: 'авг.',
					sep: 'сент.',
					oct: 'окт.',
					nov: 'нояб.',
					dec: 'дек.',
				},
				long: {
					january: 'Январь',
					february: 'Февраль',
					march: 'Март',
					april: 'Апрель',
					may: 'Май',
					june: 'Июнь',
					july: 'Июль',
					august: 'Август',
					september: 'Сентябрь',
					october: 'Октябрь',
					november: 'Ноябрь',
					december: 'Декабрь',
				}
			}
		},
		manual: "Инструкция",
		embed_manual: "Инструкция во вставке виджета на страницу",
		embed_customization: "Если вы хотите использовать дизайн кнопки, отличный от дизайна ARROUND, вставьте этот код на страницу",
		embed_customization_2: "Затем вызовите метод ниже любым удобным способом",
		copy: "Копировать",
		embed_preview: "Предварительный просмотр",
		ar_widget: "AR виджет для встраивания на web-страницу",
		arr_public_page: "Публичная бизнес-страница",
		// Login screen
		password: 'Пароль',
		forgot_pwd: 'Забыли пароль?',
		login: 'Войти',
		// Side menu
		dashboard: 'Консоль',
		users: 'Пользователи',
		business: 'Бизнес',
		rounds: 'Раунды',
		models: 'Модели',
		gifs: "GIFки",
		videos: 'Видео',
		images: 'Изображения',
		masks: 'Маски',
		options: 'Настройки',
		logout: 'Выйти',
		profile: 'Профиль',
		// Dashboard page
		welcome: 'Привет',
		new_round: 'Новый раунд',
		new_model: 'Новая модель',
		new_group: 'Новая группа',
		last_models: 'Последние модели',
		last_rounds: 'Последние раунды',
		view_all: 'Посмотреть все',
		// Users page
		search_placeholder: 'Найти...',
		// Businesses page
		new_business: 'Новый бизнес',
		businesses: 'Бизнесы',
		// Rounds page
		open_map: 'Открыть карту',
		open_list: 'Открыть список',
		round_del_title: 'Удалить раунд(ы)',
		round_del_confirm: 'Подтвердите удаление раунда(ов)',
		// Models page
		all_models: 'Все модели',
		wo_group: 'Без группы',
		groups: 'Группы',
		all: 'Все',
		active: 'Активные',
		disabled: 'Неактивные',
		edit_group: 'Изменить группу',
		// Model card
		card_refs_status: 'Без референсов',
		// User page
		sex: 'Пол',
		user: 'Пользователь',
		sex_type_1: 'Не задан',
		sex_type_2: 'Женский',
		sex_type_3: 'Мужской',
		activity: 'Активность',
		sessions: 'Сессии',
		user_sessions: 'Пользовательские сессии',
		last_rounds: 'Последние раунды',
		last_models: 'Последние модели',
		// Business page
		set_owner: 'Назначить владельца',
		business_owner_desc: 'Введите e-mail, чтобы задать владельца ARR. Новый владелец получит интсрукции на email',
		business_owner_new: 'Задать владельца',
		// Round page
		round: 'Раунд',
		comments: 'Комментарии',
		views: 'Просмотры',
		likes: 'Нравится',
		dislikes: 'Не нравится',
		round_msg: 'Сообщение',
		round_pos: 'Местоположение',
		round_model: 'Модель раунда',
		open_web: 'Открыть',
		del: 'Удалить',
		del_confirm: 'Вы действительно хотите удалить этот раунд?',
		del_round: 'Удалить раунд',
		close: 'Отмена',
		round_deleted: 'Раунд удалён',
		// Round Post
		round_plane_height: 'Высота сцены',
		round_create_title: 'Выберите тип раунда',
		// Round Post page
		round_photo: 'Фото раунд',
		round_3d: '3D раунд',
		round_yt: 'Youtube раунд',
		round_gif: 'Gif раунд',
		// Round Post Photo page
		make_round_photo: 'Создать фото раунд',
		visible_exp: 'Виден в ленте Рекомендуем',
		round_opt: 'Параметры раунда',
		sel_user: 'Выбрать пользователя',
		round_options: 'Настройки раунда',
		scale: 'Масштаб',
		height: 'Высота (см)',
		desc: 'Описание',
		lat: 'Широта',
		lng: 'Долгота',
		place_name: 'Название места',
		place_address: 'Адрес места',
		hide_loc: 'Скрыть местоположение',
		hide_loc_desc: 'Местоположение можно скрыть, но оно все ещё будет доступно в описании раунда',
		round_post: 'Создать раунд',
		// Round Post 3D page
		make_round_3d: 'Создать 3D раунд',
		load_more: 'Загрузить больше',
		// Round Post Youtube
		make_round_yt: 'Создать Youtube раунд',
		round_yt_title: 'Youtube видео',
		round_yt_url: 'Видео URL или ID',
		// New model popup
		new_model_desc: 'Укажите параметры модели',
		new_model_name: 'Название',
		round_type: 'Тип раунда',
		type_holder: 'Тип не выбран',
		group: 'Группа',
		group_holder: 'Группа не выбрана',
		save: 'Создать',
		// New group popup
		new_group_desc: 'Группы служат для организации моделей',
		new_group_name: 'Название группы',
		group_type: 'Тип группы',
		// New business popup
		new_business_desc: 'Бизнес позволяет покупателям загружать модели или создавать раунды из их админ панели. Создайте бизнес, если хотите добавить новый ARR в окружающий мир',
		new_business_name: 'Название бизнеса',
		new_business_add: 'Создать бизнес',
		// Model page
		model_modification: 'Модификация',
		model_warn: 'Внимание',
		model_title: 'Модель',
		model_name: 'Название модели',
		model_group: 'Группа модели',
		model_group_holder: 'Выбрать группу',
		model_show: 'Сделать активной',
		model_modifications: 'Модификации',
		model_mod_show: 'Сделать активной',
		model_mod_name: 'Название модификации',
		model_vis: 'Видимость модели',
		model_vis_ios: 'Видно на iOS',
		model_vis_android: 'Видно на Android',
		model_vis_web: 'Видно в Web',
		model_ios_warn: 'Нет связанного iOS архива',
		model_android_warn: 'Нет связанного Android архива',
		model_ios_mask_warn: 'Нет iOS Zip файла',
		model_ios_tex_warn: 'Нет iOS Texture файла',
		model_android_sfb_mask_warn: 'Нет Android SFB файла',
		model_android_texture_mask_warn: 'Нет Android Texture файла',
		model_web_mask_warn: 'Нет маски для Web',
		model_archives: 'Архивы модели',
		model_arch_prev: 'Предпросмотр',
		model_arch_dl: 'Скачать',
		model_arch_upl: 'Загрузить',
		model_audio: 'Аудио Модели',
		model_audio_file: 'Аудио файл',
		audio_play: 'Играть',
		audio_mode: 'Режим',
		audio_mode_once: 'Один раз',
		audio_mode_loop: 'Цикл',
		audio_mode_tap: 'По тапу',
		audio_mode_dis: 'Отключён',
		model_rec_imgs: 'Изображения для распознавания',
		model_rec_old: 'Старое распознавание',
		model_rec_server: 'Распознавание на сервере',
		model_save: 'Сохранить',
		model_updated: 'Обновлено',
		// Gif page
		gif_title: 'Gif',
		gif_name: 'Название Gif',
		gif_group: 'Группа Gif',
		gif_show: 'Сделать активным',
		gif_vis: 'Видимость Gif',
		gif_src: 'Оригинал Gif',
		gif_imgs: 'Изображения для распознавания',
		gif_warn: 'Оригинал Gif не загружен',
		// Video page
		video_title: 'Видео',
		video_name: 'Название видео',
		video_group: 'Группа видео',
		video_show: 'Сделать активным',
		video_vis: 'Видимость видео',
		video_src: 'Оригинал видео',
		video_new: 'Заменить',
		video_imgs: 'Изображения для распознавания',
		video_warn: 'Оригинал видео не загружен',
		// Image page
		img_title: 'Изображение',
		img_name: 'Название изображения',
		img_group: 'Группа изображения',
		img_show: 'Сделать активным',
		img_vis: 'Видимость изображения',
		img_imgs: 'Изображения для распознавания',
		// Mask page
		mask_title: 'Маска',
		// Ref image create
		ref_title: 'Новое изображение',
		ref_sub_title: 'Укажите параметры изображения',
		ref_pic_width: 'Ширина картинки в реальном мире (см)',
		ref_global_loc: 'Глобальное отображение',
		ref_rec_server: 'Распознавание сервером',
		ref_rec_percent: 'Точность распознавания (%)',
		ref_img_exist: 'Существует',
		ref_sticky: 'Прилипнуть к метке',
		ref_new_msg: 'Новое изображение добавлено',
		ref_edit_msg: 'Изображение изменено',
		// Ref image edit
		ref_edit_btn: 'Редактировать',
		ref_edit_title: 'Редактировать изображение',
		ref_del_confirm: 'Подтвердите удаление',
		ref_del: 'Удалить изображение',
		ref_exist_btn: 'Существует',
		ref_ocr: 'Слова с изображения',
		ref_key_words: 'Ключевые слова (через запятую)',
		ref_info_name: 'Название',
		ref_info_date: 'Дата добавления',
		ref_info_on: 'Да',
		ref_info_off: 'Нет',
		ref_info_key_words: 'Ключевые слова',
		// Options page
		//opt_lifetime_days: 'день(s)',
		opt_lifetime_title: 'Лимит раундов на карте (в днях)',
		// ARR Block
		arr_manager: 'Менеджер ARR',
		arr_mine: "Мои ARR'ы",
		arr_invite_adm: 'Пригласить админа',
		arr_invite_desc: 'Введите email администратора, чтобы его пригласить',
		arr_invite_btn: 'Пригласить',
		arr_name: 'Имя',
		arr_site: 'Сайт',
		arr_phone: 'Телефон',
		arr_about: 'О себе',
		arr_links: 'Ссылки',
		arr_admins: 'Администраторы',
		arr_embed: 'Embed ARROUND',
		arr_profile_fname: 'Имя',
		arr_profile_lname: 'Фамилия',
		arr_profile_uname: 'Никнейм',
		arr_profile_uname_exist: 'Никнейм существует',
		arr_admin_del_title: 'Удалить админа',
		arr_admin_del_desc: 'Вы уверены, что хотите удалить',
		arr_blocked_account: 'Аккаунт',
		arr_blocked_desc: 'Нам жаль, но этот аккаунт был заблокирован',
		arr_absence: 'Вы не являетесь админом ни одного бизнес аккаунта',
		// Errors
		err_ref_file: 'Формат файла не поддерживается',
		err_ref_small: 'Изображение слишком маленькое',
		err_ref_quality: 'Низкое качество изображения',
		err_ref_match: 'Совпадает с другим изображением',
		err_server: 'Ошибка сервера',
		err_ref_size: 'Размер изображения меньше рекомендуемого',
		err_ref_chars: 'Некорректные символы в ключевых словах',
		// Success
		succ_andr_arch_upd: 'Обновлён Android архив',
		succ_ios_arch_upd: 'Обновлён iOS архив',
		succ_web_arch_upd: 'Обновлён Web архив',
		succ_mask_arch_upd: 'Маска обновлена',
		succ_audio_upd: 'Аудио обновлено',
	}
}

export function createLocalization() {
  return new VueI18n({
	locale: 'ru', // set locale
	fallbackLocale: 'en', // if localization message doesn't exist
	messages, // set locale messages
  });
}
