import Vue from 'vue'
import Meta from 'vue-meta'
import App from './App.vue'
import { createStore } from './store'
import { createRouter } from './router'
import { createLocalization } from './localization'
import { sync } from 'vuex-router-sync'
import NoSSR from 'vue-no-ssr'

Vue.use(Meta);
Vue.component('no-ssr', NoSSR);

Vue.config.productionTip = false;

export function createApp () {

  const store = createStore();
  const router = createRouter(store);
  sync(store, router);

  const i18n = createLocalization();

  const app = new Vue({
    metaInfo: {
      title: 'ARROUND',
      meta: [
        { vmid: 'description', name: 'description', content: 'ARROUND Dashboard' }
      ],
      htmlAttrs: {
        lang: 'en'
      }
    },
    router,
	store,
	i18n,
    serverPrefetch () {
      if(this.$store.state.token) {
		return this.fetch().then(data => { this.$api.setToken(data.token) });
	  }
    },
    data () {
      return {
        list: [],
        history: []
      }
    },
    beforeMount() {
	  let query = {};

      if(!this.$route.meta.guest)
        query['redirect'] = this.$route.path;

      if(query['redirect'] === '/') delete query['redirect']

      if(this.$store.state.token) this.$api.setToken(this.$store.state.token);
	  else if(!this.$route.meta.guest) this.$router.push({ path: '/login', query})

	  this.$i18n.locale = localStorage.getItem('language') || 'ru';
	  this.$store.dispatch('language', this.$i18n.locale);
    },
    methods: {
      fetch () {
        return this.$store.dispatch('fetch');
      }
    },
    render: h => h(App)
  });

  return { app, router, store, i18n }
}
