let Render = function (image) {
  return new Promise(function(resolve, reject) {

    if(!image) reject();

    let img = new Image();
    img.src = image;
    img.onload = () => {
      resolve(img.src);
    };
  })
};

export default {
  install: function(Vue) {
    Vue.prototype.$render = Render
  }
}
